import './App.css';
import "bootstrap-icons/font/bootstrap-icons.css";

function App() {
  return (
    <div className="App flex min-h-screen flex-col items-center justify-between lg:p-24 gap-y-28">
      <nav className="navbar">
        <ol className="invisible flex lg:visible space-x-36">
          <a href="/" className="home hover:underline hover:underline-offset-8">Home</a>
          <a href="#about" className="about-nav hover:underline hover:underline-offset-8">About</a>
          <a href="#projects" className="projects hover:underline hover:underline-offset-8">Projects</a>
          <a href="#contact" className="contact hover:underline hover:underline-offset-8">Contact</a>
        </ol>
      </nav>
      
      <section id="about" className="lg: flex flex-col items-center gap-y-7 px-5 text-center text-center">
        <h2 className={`mb-3 text-2xl font-semibold`}>{`Hi! My name is Arlena McClenton and I'm a technologist based in NYC.`}</h2>
        <img id="about-img" src="about.jpg" alt="headshot of young woman in a striped dress who is smiling" width={300} height={300}></img>
        <h3> {`I'm a frontend engineer who's obssessed with creating clean, smooth user experiences. Previously, I was a QA engineer at Nike, where I worked on the iOS and Android versions of the SNKRS app. When I'm not building things, I'm usually learning new shapes on aerial hoop!`}</h3>
        <a href="#projects" className='bi-caret-down text-4xl'></a>
      </section>
      
      {/* <section id="projects" className="flex flex-col">
          <div className="mb-32 text-center flex flex-col items-center md:flex-row">

              <h2 className="mb-2 text-2xl font-semibold group rounded-lg border border-transparent px-5 py-4">
                {`Here's some of my work`}:
              </h2>

            <a
              href="https://github.com/ArlenaM/TicTacToe"
              className="group rounded-lg border border-transparent px-5 py-4 transition-colors hover:border-indigo-300 hover:bg-indigo-100"
              rel="noopener noreferrer"
              target="_blank"
            >
              <h2 className={`mb-2 text-2xl font-semibold`}>
                Tic Tac Toe
              </h2>
              <i className='bi-circle'></i>
              <i className='bi-x-lg'></i>
              <img src="\tictactoe.png" alt="picture of game of tic tac toe" width={225} height={225}></img>
              <p className={`m-0 max-w-[30ch] text-md opacity-75`}>
                Tech: Vanilla Javascript
              </p>
            </a>

            <a
              href=""
              className="group rounded-lg border border-transparent px-5 py-4 transition-colors hover:border-indigo-300 hover:bg-indigo-100"
              rel="noopener noreferrer"
              target="_blank"
            >
              <h2 className={`mb-2 text-2xl font-semibold`}>
                Personal Website
              </h2>
              <i className='bi-terminal-fill'></i>
              <img src="\personal-site.png" alt="a small screenshot of a personal website" width={300} height={300}></img>
              <p className={`m-0 max-w-[30ch] text-sm opacity-75`}>
                Tech: Tailwind CSS, React
              </p>
            </a>

            <a
              href="https://github.com/ArlenaM/TicTacToe"
              className="group rounded-lg border border-transparent px-5 py-4 transition-colors hover:border-indigo-300 hover:bg-indigo-100"
              rel="noopener noreferrer"
            >
              <h2 className={`lg: mb-2 text-2xl font-semibold`}>
                Weather App
              </h2>
              <i className='bi-sun'></i>
              <img src="https://placehold.co/200x200"></img>
              <p className={`lg: m-0 max-w-[30ch] text-md opacity-75`}>
                Tech: Vanilla Javascript
              </p>
            </a> 
            
        </div>
      </section>*/}

      <section id='contact'>
        <h2 className={`mb-3 text-2xl font-semibold text-center`}>{`Let's get in touch!`}</h2>
          <div className='social-icons text-center space-x-28 lg:space-x-32'>
            <a href="https://github.com/ArlenaM" className='bi-github'></a>
            <a href="https://www.linkedin.com/in/arlena-mcclenton/" className='bi-linkedin'></a>
          </div>
          <div>
            <p>Designed and developed by Arlena McClenton</p>
          </div>
      </section>
    
    </div>
  );
}

export default App;
